import Vue from "vue";
import App from "./app/index.vue";
import router from "./router";
import store from "./store";
import * as types from "./store/types";
// import "./registerServiceWorker";
import i18n from "./utlities/i18n/i18n";
import EvaIcons from "vue-eva-icons";
import { BootstrapVue } from "bootstrap-vue";
import "./assets/style/style.scss";
import axios from "axios";
import VueAxios from "vue-axios";
import VueClipboard from "vue-clipboard2";

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);

Vue.use(EvaIcons);

// Install BootstrapVue
Vue.use(BootstrapVue);
// clipboard
VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);
// Optionally install the BootstrapVue icon components plugin
Vue.use(require("vue-moment-jalaali"));

router.beforeEach((to, from, next) => {
    store.dispatch(types.ACTION_CHECK_IS_AUTH);
    let language = to.params.lang;
    if (!language) {
        language = "fa";
    }
    i18n.locale = language;
    next();
});

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
