import * as types from "./../../types";

const state = {
    isLoaded: false,
    transactions: "",
};

const getters = {
    [types.GET_IS_TRANSACTIONS_LOADED]: (state) => {
        return state.isLoaded;
    },
    [types.GET_TRANSACTIONS]: (state) => {
        return state.transactions;
    },
};

const mutations = {
    [types.MUTATE_TRANSACTIONS]: (state, payload) => {
        state.transactions = payload;
        state.isLoaded = true;
    },
};

const actions = {
    [types.ACTION_TRANSACTIONS]: ({ commit }, payload) => {
        commit(types.MUTATE_TRANSACTIONS, payload);
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
