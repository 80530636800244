class GetAccessTokenService {
    constructor() {}

    GetAccessToken() {
        return localStorage.getItem("accessToken");
    }

    GetBitrahAccessToken() {
        return localStorage.getItem("bitrahAccessToken");
    }

    GetBitrahAdminAccessToken() {
        return localStorage.getItem("bitrahAdminAccessToken");
    }
}

export default new GetAccessTokenService();
