import * as types from "../../types";

const state = {
    userGateway:"",
};

const getters = {
    
    [types.GET_USER_GATEWAY_STATUS]: (state) => {
        return state.userGateway;
    },
};

const mutations = {
    
    [types.MUTATE_USER_GATEWAY_STATUS]: (state, payload) => {
        state.userGateway = payload;
    },
};

const actions = {
    
    [types.ACTION_USER_GATEWAY_STATUS]: ({ commit }, payload) => {
        commit(types.MUTATE_USER_GATEWAY_STATUS, payload);
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
