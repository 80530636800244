import * as types from "./../../types";

const state = {
    orders: "",
    orderDetails: "",
    calculateValue:"",
};

const getters = {
    [types.GET_LATEST_ORDERS]: (state) => {
        return state.latestOrders;
    },
    [types.GET_ORDERS]: (state) => {
        return state.orders;
    },
    [types.GET_ORDER_DETAILS]: (state) => {
        return state.orderDetails;
    },
    [types.GET_CALCULATE_VALUE]: (state) => {
        return state.calculateValue;
    },
};

const mutations = {
    [types.MUTATE_ORDERS]: (state, payload) => {
        state.orders = payload;
    },
    [types.MUTATE_ORDER_DETAILS]: (state, payload) => {
        state.orderDetails = payload;
    },
    [types.MUTATE_MAKE_ORDER_DETAILS_EMPTY]: (state) => {
        state.orderDetails = "";
    },
    [types.MUTATE_CALCULATE_VALUE]: (state) => {
        return state.calculateValue;
    },
};

const actions = {
    [types.ACTION_LATEST_ORDERS]: ({ commit }, payload) => {
        commit(types.MUTATE_LATEST_ORDERS, payload);
    },
    [types.ACTION_ORDERS]: ({ commit }, payload) => {
        commit(types.MUTATE_ORDERS, payload);
    },
    [types.ACTION_ORDER_DETAILS]: ({ commit }, payload) => {
        commit(types.MUTATE_ORDER_DETAILS, payload);
    },
    [types.ACTION_MAKE_ORDER_DETAILS_EMPTY]: ({ commit }) => {
        commit(types.MUTATE_MAKE_ORDER_DETAILS_EMPTY);
    },
    [types.ACTION_CALCULATE_VALUE]: ({ commit }) => {
        commit(types.MUTATE_CALCULATE_VALUE);
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
