import axios from "axios";
// import { BASE_URL }          from "./../base";
// import store from "./../../store/index";
import router from "./../../router/index";
import store from "./../../store/index";
import i18n from "../../utlities/i18n/i18n";

import GetAccessTokenService from "./../getAccessToken-service";

const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods': 'POST, GET, PUT, OPTIONS, DELETE',
        // 'Access-Control-Allow-Headers': 'Access-Control-Allow-Methods, Access-Control-Allow-Origin, Origin, Accept, Content-Type',
        'Content-Type': 'application/json',
        Accept: 'application/json'
    },
});
instance.interceptors.request.use(
    (config) => {
        const token = GetAccessTokenService.GetAccessToken();
        if (token) {
            config.headers["Authentication"] = "bearer " + token;
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);
instance.interceptors.response.use(
    (response) => {
        return response.data;
    },
    function (error) {
        if (error.response.status === 401) {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("bitrahAccessToken");
            localStorage.removeItem("bitrahAdminAccessToken");
            localStorage.setItem("isAuth", false);
            store.commit("checkIsAuth");
            router.push(`/${i18n.locale}/account/login`);
        }
        return Promise.reject(error.response);
    }
);
export default instance;
