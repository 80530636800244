import Vue from "vue";
import Vuex from "vuex";
import theme from "./modules/theme/theme";
import auth from "./modules/auth/auth";
import orders from "./modules/orders/orders";
import currencies from "./modules/currencies/currencies";
import wallet from "./modules/wallet/wallet";
import transactions from "./modules/transactions/transactions";
import user from "./modules/user/user";
import merchant from "./modules/merchant/merchant";
import userGateWay from "./modules/user/userGateway";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    theme,
    auth,
    currencies,
    orders,
    wallet,
    transactions,
    userGateWay,
    user,
    merchant
  }
});
