import * as types from "./../../types";

const state = {
    userInfo: "",
};

const getters = {
    [types.GET_USER_INFO]: (state) => {
        return state.userInfo;
    },
};

const mutations = {
    [types.MUTATE_USER_INFO]: (state, payload) => {
        state.userInfo = payload;
    },
};

const actions = {
    [types.ACTION_USER_INFO]: ({ commit }, payload) => {
        commit(types.MUTATE_USER_INFO, payload);
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
