import * as types from "./../../types";

const state = {
    currencies: "",
    bitrahCurrencies: "",
};

const getters = {
    [types.GET_CURRENCIES]: (state) => {
        return state.currencies;
    },
    [types.GET_BITRAH_CURRENCIES]: (state) => {
        return state.bitrahCurrencies;
    },
};

const mutations = {
    [types.MUTATE_CURRENCIES]: (state, payload) => {
        state.currencies = payload;
    },
    [types.MUTATE_BITRAH_CURRENCIES]: (state, payload) => {
        state.bitrahCurrencies = payload;
    },
};

const actions = {
    [types.ACTION_CURRENCIES]: ({ commit }, payload) => {
        commit(types.MUTATE_CURRENCIES, payload);
    },
    [types.ACTION_BITRAH_CURRENCIES]: ({ commit }, payload) => {
        commit(types.MUTATE_BITRAH_CURRENCIES, payload);
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
