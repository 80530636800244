import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import i18n from "./../utlities/i18n/i18n";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: `/${i18n.locale}`
  },
  {
    path: "/:lang",
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "/",
        meta: { transitionName: "slide" },
        name: "home",
        component: () => import("../pages/home/index.vue"),
        beforeEnter: (to, from, next) => {
          if (!store.state.auth.isAuth) next();
          else {
            if (localStorage.getItem("bitrahAccessToken")) {
              next(`/${i18n.locale}/panel/user/dashboard`);
            }
            if (localStorage.getItem("bitrahAdminAccessToken")) {
              next(`/${i18n.locale}/panel/admin/transactions`);
            }
          }
        }
      },
      {
        path: "/:lang/completed",
        meta: { transitionName: "slide" },
        name: "completed",
        component: () => import("../components/completedOrder/index.vue")
      },
      {
        path: "/:lang/plugins",
        meta: { transitionName: "slide" },
        name: "plugins",
        component: () => import("../pages/plugins/index.vue")
      },
      {
        path: "/:lang/guide",
        meta: { transitionName: "slide" },
        name: "guide",
        component: () => import("../pages/home/guide/index.vue")
      },
      {
        path: "/:lang/documents",
        meta: { transitionName: "slide" },
        name: "document",
        component: () => import("../pages/document/index.vue")
      },
      {
        path: "/:lang/payment-demo",
        meta: { transitionName: "slide" },
        name: "payWithBitrah",
        component: () => import("../pages/payWithBitrah/index.vue")
      },
      {
        path: "/:lang/account/login",
        meta: { transitionName: "slide" },
        name: "login",
        component: () => import("../pages/login/index.vue"),
        beforeEnter: (to, from, next) => {
          if (!store.state.auth.isAuth) next();
          else next("/panel");
        }
      },
      {
        path: "/:lang/account/register",
        meta: { transitionName: "slide" },
        name: "register",
        component: () => import("../pages/register/index.vue"),
        beforeEnter: (to, from, next) => {
          if (!store.state.auth.isAuth) next();
          else next("/panel");
        }
      },
      {
        path: "/:lang/:id",
        meta: { transitionName: "slide" },
        name: "merchantPage",
        component: () => import("../pages/merchant/index.vue")
      },
      {
        path: "/:lang/:id/customer",
        meta: { transitionName: "slide" },
        name: "customer",
        component: () => import("../pages/merchant/customer/index.vue")
      },
      {
        path: "/:lang/panel",
        name: "panel",
        component: () => import("../pages/panel/index.vue"),
        beforeEnter: (to, from, next) => {
          if (store.state.auth.isAuth) next();
          else next(`/${i18n.locale}/account/login`);
        },
        children: [
          {
            path: "admin",
            name: "admin",
            redirect: `admin/transactions`,
            component: () => import("../pages/panel/admin/index.vue"),
            children: [
              {
                path: "transactions",
                name: "admin-transactions",
                component: () =>
                  import("../pages/panel/admin/transactions/index.vue")
              },
              {
                path: "merchant-Online-Request",
                name: "admin-merchantOnlineRequest",
                component: () =>
                  import("../pages/panel/admin/merchantOnlineRequest/index.vue")
              },
              {
                path: "merchant-list",
                name: "admin-merchantList",
                component: () =>
                  import("../pages/panel/admin/merchantList/index.vue")
              },
              {
                path: "merchant-single-info",
                name: "admin-merchantSingleInfo",
                component: () =>
                  import("../pages/panel/admin/merchantSingleInfo/index.vue")
              },
              {
                path: "merchant-transactions",
                name: "admin-merchantTransactions",
                component: () =>
                  import("../pages/panel/admin/merchantTransactions/index.vue")
              },
              {
                path: "makeWalletEmpty",
                name: "makeWalletEmpty",
                component: () =>
                  import("../pages/panel/admin/makeWalletEmpty/index.vue")
              },
              {
                path: "submitted-orders",
                name: "submitted-orders",
                component: () =>
                  import("../pages/panel/admin/submittedOrders/index.vue")
              },
              {
                path: "settlement-request",
                name: "settlement-request",
                component: () =>
                  import("../pages/panel/admin/settlementRequest/index.vue")
              },
              {
                path: "failed-webhook",
                name: "failed-webhook",
                component: () =>
                  import("../pages/panel/admin/failedWebhook/index.vue")
              },
              {
                path: "failed-evacuation",
                name: "failed-evacuation",
                component: () =>
                  import("../pages/panel/admin/failedEvacuation/index.vue")
              },
              {
                path: "unsuccessful-inquiry",
                name: "unsuccessful-inquiry",
                component: () =>
                  import("../pages/panel/admin/unsuccessfulInquiry/index.vue")
              },
              {
                path: "corrupted-payment",
                name: "corrupted-payment",
                component: () =>
                  import("../pages/panel/admin/corruptedPayment/index.vue")
              },
              {
                path: "wallet-management",
                name: "wallet-management",
                component: () =>
                  import("../pages/panel/admin/walletManagement/index.vue")
              },
              {
                path: "failed-ether-injection",
                name: "failed-ether-injection",
                component: () =>
                  import("../pages/panel/admin/failedEtherInjection/index.vue")
              },
              {
                path: "ether-wallet-management",
                name: "ether-wallet-management",
                component: () =>
                  import("../pages/panel/admin/etherWalletManagement/")
              },
              {
                path: "employee",
                name: "employee",
                component: () => import("../pages/panel/admin/employee/")
              },
              {
                path: "roles",
                name: "roles",
                component: () => import("../pages/panel/admin/roles/")
              }
            ]
          },
          {
            path: "user",
            name: "user",
            redirect: `user/dashboard`,
            component: () => import("../pages/panel/user/index.vue"),

            children: [
              {
                path: "dashboard",
                name: "dashboard",
                component: () =>
                  import("../pages/panel/user/dashboard/index.vue")
              },
              {
                path: "profile",
                name: "user-profile",
                component: () => import("../pages/panel/user/profile/index.vue")
              },
              {
                path: "transactions",
                name: "user-transactions",
                component: () =>
                  import("../pages/panel/user/transactions/index.vue")
              },
              {
                path: "payment",
                name: "payment",
                component: () => import("../pages/panel/user/payment/index.vue")
              },
              {
                path: "wallets",
                name: "wallets",
                component: () => import("../pages/panel/user/wallets/index.vue")
              },
              {
                path: "account-setting",
                name: "account-setting",
                component: () =>
                  import("../pages/panel/user/account-setting/index.vue")
              },
              {
                path: "merchantId",
                name: "merchantId",
                component: () =>
                  import("../pages/panel/user/merchantId/index.vue")
              }
            ]
          }
        ]
      },
      {
        path: "*",
        meta: { transitionName: "slide" },
        name: "notfound",
        component: () => import("../pages/notFound/index.vue")
      }
    ]
  }
];
const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
