/* ********* Getters ********* */
//THEME
export const GET_IS_DARK = "theme/IS_DARK";

//AUTH
export const GET_IS_AUTH = "auth/IS_AUTH";
export const GET_ACCESS_TOKEN = "auth/ACCESS_TOKEN";
export const GET_CATEGORIES = "auth/CATEGORIES";
export const GET_INFO_FOR_REGISTER = "auth/INFO_FOR_REGISTER";
export const GET_INFO_FOR_REGISTER_FLAG = "auth/INFO_FOR_REGISTER_FLAG";

//ORDERS
export const GET_LATEST_ORDERS = "orders/LATEST_ORDERS";
export const GET_ORDERS = "orders/ORDERS";
export const GET_ORDER_DETAILS = "orders/ORDER_DETAILS";
export const GET_CALCULATE_VALUE = "orders/CALCULATE_VALUE";

//TRANSACTIONS
export const GET_IS_TRANSACTIONS_LOADED = "transactions/isLoaded";
export const GET_TRANSACTIONS = "transactions/TRANSACTIONS";

//CURRENCIES
export const GET_CURRENCIES = "currencies/CURRENCIES";
export const GET_BITRAH_CURRENCIES = "currencies/BITRAH_CURRENCIES";

//WALLET
export const GET_IS_WALLET_LOADED = "wallet/isLoaded";
export const GET_WALLET = "wallet/WALLET";

//USER
export const GET_USER_INFO = "user/USER_INFO";
export const GET_USER_GATEWAY_STATUS = "user/USER_GATEWAY";

//MERCHANT
export const GET_MERCHANT = "merchant/MERCHANT";
export const GET_MERCHANT_LIST = "merchant/MERCHANT_LIST";

/* ********* Mutations ********* */

//THEME
export const MUTATE_IS_DARK = "theme/MUTATE_IS_DARK";

//AUTH
export const MUTATE_CHECK_IS_AUTH = "auth/MUTATE_CHECK_IS_AUTH";
export const MUTATE_ACCESS_TOKEN = "auth/MUTATE_ACCESS_TOKEN";
export const MUTATE_AUTO_ACCESS_TOKEN = "auth/MUTATE_AUTO_ACCESS_TOKEN";
export const MUTATE_CATEGORIES = "auth/MUTATE_CATEGORIES";
export const MUTATE_INFO_FOR_REGISTER = "auth/MUTATE_INFO_FOR_REGISTER";

//ORDERS
export const MUTATE_LATEST_ORDERS = "orders/MUTATE_LATEST_ORDERS";
export const MUTATE_ORDERS = "orders/MUTATE_ORDERS";
export const MUTATE_ORDER_DETAILS = "orders/MUTATE_ORDER_DETAILS";
export const MUTATE_MAKE_ORDER_DETAILS_EMPTY =
    "orders/MUTATE_MAKE_ORDER_DETAILS_EMPTY";
export const MUTATE_CALCULATE_VALUE = "orders/MUTATE_CALCULATE_VALUE";

//TRANSACTIONS
export const MUTATE_TRANSACTIONS = "transactions/MUTATE_TRANSACTIONS";

//CURRENCIES
export const MUTATE_CURRENCIES = "currencies/MUTATE_CURRENCIES";
export const MUTATE_BITRAH_CURRENCIES = "currencies/MUTATE_BITRAH_CURRENCIES";

//WALLET
export const MUTATE_WALLET = "wallet/MUTATE_WALLET";

//USER
export const MUTATE_USER_INFO = "user/MUTATE_USER_INFO";
export const MUTATE_USER_GATEWAY_STATUS = "user/MUTATE_USER_GATEWAY";

//MERCHANT
export const MUTATE_MERCHANT = "merchant/MUTATE_MERCHANT";
export const MUTATE_MERCHANT_LIST = "merchant/MUTATE_MERCHANT_LIST";

/* ********* Actions ********* */

//theme
export const ACTION_IS_DARK = "theme/ACTION_IS_DARK";

//AUTH
export const ACTION_CHECK_IS_AUTH = "auth/ACTION_CHECK_IS_AUTH";
export const ACTION_ACCESS_TOKEN = "auth/ACTION_ACCESS_TOKEN";
export const ACTION_AUTO_ACCESS_TOKEN = "auth/ACTION_AUTO_ACCESS_TOKEN";
export const ACTION_CATEGORIES = "auth/ACTION_CATEGORIES";
export const ACTION_INFO_FOR_REGISTER = "auth/ACTION_INFO_FOR_REGISTER";

//ORDERS
export const ACTION_LATEST_ORDERS = "orders/ACTION_LATEST_ORDERS";
export const ACTION_ORDERS = "orders/ACTION_ORDERS";
export const ACTION_ORDER_DETAILS = "orders/ACTION_ORDER_DETAILS";
export const ACTION_MAKE_ORDER_DETAILS_EMPTY =
    "orders/ACTION_MAKE_ORDER_DETAILS_EMPTY";
export const ACTION_CALCULATE_VALUE = "orders/ACTION_CALCULATE_VALUE";

//TRANSACTIONS
export const ACTION_TRANSACTIONS = "transactions/ACTION_TRANSACTIONS";

//CURRENCIES
export const ACTION_CURRENCIES = "currencies/ACTION_CURRENCIES";
export const ACTION_BITRAH_CURRENCIES = "currencies/ACTION_BITRAH_CURRENCIES";

//WALLET
export const ACTION_WALLET = "wallet/ACTION_WALLET";

//USER
export const ACTION_USER_INFO = "user/ACTION_USER_INFO";
export const ACTION_USER_GATEWAY_STATUS = "user/ACTION_USER_GATEWAY";

//MERCHANT
export const ACTION_MERCHANT = "merchant/ACTION_MERCHANT";
export const ACTION_MERCHANT_LIST = "merchant/ACTION_MERCHANT_LIST";
