import * as types from "./../../types";

const state = {
    isDark: "",
};

const getters = {
    [types.GET_IS_DARK]: (state) => {
        return state.isDark;
    },
};

const mutations = {
    [types.MUTATE_IS_DARK]: (state) => {
        localStorage.getItem("theme") == "darkTheme"
            ? (state.isDark = true)
            : (state.isDark = false);
    },
};

const actions = {
    [types.ACTION_IS_DARK]: ({ commit }) => {
        commit(types.MUTATE_IS_DARK);
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
