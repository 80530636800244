import axios from "axios";
import GetAccessTokenService from "../getAccessToken-service";
import store from "../../store";
import router from "../../router";
import i18n from "../../utlities/i18n/i18n";
// import { BITRAH_URL } from "./../base";

const instance = axios.create({
    baseURL: process.env.VUE_APP_BITRAH_BASE_URL,
    headers: {
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods': 'POST, GET, PUT, OPTIONS, DELETE',
        // 'Access-Control-Allow-Headers': 'Access-Control-Allow-Methods, Access-Control-Allow-Origin, Origin, Accept, Content-Type',
        'Content-Type': 'application/json',
        Accept: 'application/json'
    },
});
instance.interceptors.response.use(
    (response) => {
        return response.data;
    },
    function(error) {
        let token;
        if (!GetAccessTokenService.GetBitrahAccessToken()) {
            token = GetAccessTokenService.GetBitrahAdminAccessToken();
        }
        if (!GetAccessTokenService.GetBitrahAdminAccessToken()) {
            token = GetAccessTokenService.GetBitrahAccessToken();
        }
        if (!token && error.response.status === 401) {
            localStorage.setItem("isAuth", "false");
            localStorage.removeItem("accessToken");
            localStorage.removeItem("bitrahAccessToken");
            localStorage.removeItem("bitrahAdminAccessToken");
            store.commit("checkIsAuth");
            router.push(`/${i18n.locale}/account/login`);
        }
        return Promise.reject(error.response);
    }
);
export default instance;
