import * as types from "./../../types";

const state = {
    merchant: "",
    merchantList:"",
};

const getters = {
    [types.GET_MERCHANT]: (state) => {
        return state.merchant;
    },
    [types.GET_MERCHANT_LIST]: (state) => {
        return state.merchantList;
    },
};

const mutations = {
    [types.MUTATE_MERCHANT]: (state, payload) => {
        state.merchant = payload;
    },
    [types.MUTATE_MERCHANT_LIST]: (state, payload) => {
        state.merchantList = payload;
    },
};

const actions = {
    [types.ACTION_MERCHANT]: ({ commit }, payload) => {
        commit(types.MUTATE_MERCHANT, payload);
    },
    [types.ACTION_MERCHANT_LIST]: ({ commit }, payload) => {
        commit(types.MUTATE_MERCHANT_LIST, payload);
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
