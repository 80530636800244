import ThemingService from "../theme/theme";
import axios from "./../services/config/main-axios";
import authAxios from "./../services/config/need-auth-axios";
import bitrahAxios from "./../services/config/bitrah-axios";
import TransitionPage from "@/components/transitionPage";

const headerComp = () => import("./header/index.vue");
const footerComp = () => import("./footer/index.vue");
export default {
  name: "app",
  components: {
    headerComp,
    footerComp,
    TransitionPage
  },
  props: [],
  data() {
    return {
      checkHeaderStyle: false,
      checkHeader: false,
      checkIsPanel: false
    };
  },
  computed: {},

  beforeCreate() {
    // STORE LANG IN LOCALE STORAGE ON THE INITIAL LOADING OF THE APP
    localStorage.setItem("lang", this.$i18n.locale);

    let a = new ThemingService();
    let defaultTheme = localStorage.getItem("theme");
    if (defaultTheme != null) {
      a.changeTheme(defaultTheme);
    } else {
      a.changeTheme("lightTheme");
    }
    let defaultLang = localStorage.getItem("lang");
    if (defaultLang != null) {
      axios.defaults.headers.common["Accept-Language"] = defaultLang;
      authAxios.defaults.headers.common["Accept-Language"] = defaultLang;
      bitrahAxios.defaults.headers.common["Accept-Language"] = defaultLang;
    } else {
      axios.defaults.headers.common["Accept-Language"] = "fa";
      authAxios.defaults.headers.common["Accept-Language"] = "fa";
      bitrahAxios.defaults.headers.common["Accept-Language"] = "fa";
    }
  },
  methods: {},
  watch: {
    $route(to) {
      let realPath = to.path.replace(`${to.params.lang}`, "");
      if (realPath.includes("//")) {
        realPath = realPath.replace(`//`, "/");
      }
      realPath === "/" ||
      realPath === "/payWithBitrah" ||
      realPath === "/completed"
        ? (this.checkHeaderStyle = true)
        : (this.checkHeaderStyle = false);
      // Object.keys( to.params ).length !== 0 ||
      realPath.includes("/payWithBitrah") ||
      realPath.includes("/completed") ||
      realPath.includes("/guest") ||
      realPath.includes("/customer")
        ? (this.checkHeader = false)
        : (this.checkHeader = true);
      realPath.includes("panel")
        ? (this.checkIsPanel = true)
        : (this.checkIsPanel = false);
    }
  }
};
