import * as types from "./../../types";
import store      from "../..";

const state = {
    isAuth : false ,
    accessToken : "" ,
    categories : "" ,
    infoForRegister : "",
    infoForRegisterFlag: false
};

const getters = {
    [ types.GET_IS_AUTH ] : ( state ) => {
        return state.isAuth;
    } ,
    [ types.GET_ACCESS_TOKEN ] : ( state ) => {
        return state.accessToken;
    } ,
    [ types.GET_CATEGORIES ] : ( state ) => {
        return state.categories;
    } ,
    [ types.GET_INFO_FOR_REGISTER ] : ( state ) => {
        return state.infoForRegister;
    } ,
    [ types.GET_INFO_FOR_REGISTER_FLAG ] : ( state ) => {
        return state.infoForRegisterFlag;
    } ,
};

const mutations = {
    [ types.MUTATE_CHECK_IS_AUTH ] : ( state ) => {
        localStorage.getItem( "isAuth" ) == "true" &&
        ( localStorage.getItem( "bitrahAccessToken" ) ||
            localStorage.getItem( "bitrahAdminAccessToken" ) )
            ? ( state.isAuth = true )
            : ( state.isAuth = false );
    } ,
    [ types.MUTATE_ACCESS_TOKEN ] : ( state , payload ) => {
        state.accessToken = payload;
    } ,

    [ types.MUTATE_AUTO_ACCESS_TOKEN ] : () => {
        const token = localStorage.getItem( "accessToken" );
        if ( !token ) {
            store.dispatch( types.ACTION_ACCESS_TOKEN , "" );
            return;
        }
        store.dispatch( types.ACTION_ACCESS_TOKEN , token );
    } ,
    [ types.MUTATE_CATEGORIES ] : ( state , payload ) => {
        state.categories = payload;
    } ,
    [ types.MUTATE_INFO_FOR_REGISTER ] : ( state , payload ) => {
        state.infoForRegister = payload;
        state.infoForRegisterFlag = true;
    } ,
};

const actions = {
    [ types.ACTION_CHECK_IS_AUTH ] : ( { commit } ) => {
        commit( types.MUTATE_CHECK_IS_AUTH );
    } ,
    [ types.ACTION_ACCESS_TOKEN ] : ( { commit } , payload ) => {
        commit( types.MUTATE_ACCESS_TOKEN , payload );
    } ,

    [ types.ACTION_AUTO_ACCESS_TOKEN ] : ( { commit } ) => {
        commit( types.MUTATE_AUTO_ACCESS_TOKEN );
    } ,
    [ types.ACTION_CATEGORIES ] : ( { commit } , payload ) => {
        commit( types.MUTATE_CATEGORIES , payload );
    } ,
    [ types.ACTION_INFO_FOR_REGISTER ] : ( { commit } , payload ) => {
        commit( types.MUTATE_INFO_FOR_REGISTER , payload );
    } ,
};

export default {
    state ,
    getters ,
    mutations ,
    actions ,
};
