const themes = {
    darkTheme: {
        white: "#ffffff",
        black: "#000000",
        "background-color": "#14192f",
        "primary-header-box-shadow": "none",
        "primary-header-menu-color": "",
        "primary-header-font-color": "",
        "primary-button-color": "",
        "primary-login-button-font-color": "",
        "disabled-color": "#192038",
        "lighter-primary-color": "",
        "lighter-primary-color-noTransparent": "",
        "logo-color": "",
        "secondary-lighter-color": "",
        "danger-lighter-color": "",
        "warning-color": "",
        "warning-lighter-color": "",
        "success-lighter-color": "",
        "purple-backgroundColor": "",
        "darkest-backgroundColor": "",
        "lightest-backgroundColor": "",
        "darker-input-backgroundColor": "",
        "footer-backgroundColor": "",
        "primary-color": "#36f",
        "secondary-color": "#1f2935",
        "danger-color": "#ff3d71",
        "success-color": "#00d68f",
        "darker-backgroundColor": "#151a30",
        "lighter-backgroundColor": "#222b45",
        "darker-fontColor": "#8f9bb3",
        "darkest-fontColor": "#fff",
        "lighter-fontColor": "#ffffff",
        "input-backgroundColor": "#0f1324",
        "primary-form-footer-bg": "#f6f8fe",
    },
    lightTheme: {
        white: "#ffffff",
        black: "#000000",
        "background-color": "#edf1f7",
        "primary-header-box-shadow": "0px 5px 18px 0 rgba(73, 115, 227, .1)",
        "primary-header-menu-color": "#1e1e1e",
        "primary-header-font-color": "#2d2d2d",
        "primary-button-color": "#00d68f",
        "primary-login-button-font-color": "#232323",
        "disabled-color": "#edf1f7",
        "lighter-primary-color": "rgba(51, 102, 255, .08)",
        "lighter-primary-color-noTransparent": "rgb(224, 231, 255)",
        "logo-color": "#2e3192",
        "secondary-lighter-color": "rgba(0, 214, 143, .08)",
        "danger-lighter-color": "rgba(255, 61, 113, .08)",
        "warning-color": "#fa0",
        "warning-lighter-color": "rgba(255, 170, 0, .08)",
        "success-lighter-color": "rgba(0, 214, 143, .08)",
        "purple-backgroundColor": "#9c27b0",
        "darkest-backgroundColor": "#edf1f7",
        "lightest-backgroundColor": "#ffffff",
        "darker-input-backgroundColor": "#283350",
        "footer-backgroundColor": "#ffffff",
        "primary-color": "#36f",
        "secondary-color": "#00d68f",
        "danger-color": "#ff3d71",
        "success-color": "#00d68f",
        "darker-backgroundColor": "#f7f7f7",
        "lighter-backgroundColor": "#ffffff",
        "darker-fontColor": "#7f8aa0",
        "darkest-fontColor": "#222b45",
        "lighter-fontColor": "#8b8b8b",
        "input-backgroundColor": "#f7f9fc",
        "primary-form-footer-bg": "#f6f8fe",
    },
    /* cascoTheme: {
     "primary-color": "#f16820",
     "background-color": "#1a3264",
     "text-color": "#e8e2da"
     },
     tealTheme: {
     "primary-color": "#ff4081",
     "background-color": "#00796b",
     "text-color": "#ffffff"
     } */
};

export default class ThemingService {
    changeTheme(themeVal) {
        localStorage.setItem("theme", themeVal);
        this.setTheme(themes[themeVal]);
    }

    setTheme(themeVal) {
        Object.keys(themeVal).forEach((k) =>
            document.documentElement.style.setProperty(`--${k}`, themeVal[k])
        );
    }
}
