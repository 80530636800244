import axios from "axios";
// import { BASE_URL } from "./../base";

const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods': 'POST, GET, PUT, OPTIONS, DELETE',
        // 'Access-Control-Allow-Headers': 'Access-Control-Allow-Methods, Access-Control-Allow-Origin, Origin, Accept, Content-Type',
        'Content-Type': 'application/json',
        Accept: 'application/json'
    },
});
instance.interceptors.response.use(
    (response) => {
        return response.data;
    });
export default instance;
