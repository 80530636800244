import * as types from "./../../types";

const state = {
    isLoaded: false,
    wallet: "",
};

const getters = {
    [types.GET_IS_WALLET_LOADED]: (state) => {
        return state.isLoaded;
    },
    [types.GET_WALLET]: (state) => {
        return state.wallet;
    },
};

const mutations = {
    [types.MUTATE_WALLET]: (state, payload) => {
        state.wallet = payload;
        state.isLoaded = true;
    },
};

const actions = {
    [types.ACTION_WALLET]: ({ commit }, payload) => {
        commit(types.MUTATE_WALLET, payload);
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
